import React, { useEffect, useMemo, useState } from 'react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { getPhantomWallet } from '@solana/wallet-adapter-wallets';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
// import { Dropdown, DropdownButton } from 'react-bootstrap';


// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');

// const LOCALHOST = "http://127.0.0.1:8899";
// const TESTNET = "https://api.testnet.solana.com";
// const DEVNET = "https://api.devnet.solana.com";
// const MAINNET1 = "https://api.mainnet-beta.solana.com";
const MAINNET2 = "https://solana-api.projectserum.com";

export const SolanaProvider = ({ children }) => {

    //const network = WalletAdapterNetwork.Devnet;
    // const endpoint = useMemo(() => clusterApiUrl(network), [network]);
    // const defaultNetwork = localStorage.getItem("MM-default-network");
    // const [network, setNetwork] = useState(defaultNetwork === null ? MAINNET2 : defaultNetwork);
    const [network, ] = useState(MAINNET2);
    const endpoint = useMemo(() => network, [network]);
    
    const wallets = useMemo(() => [
        getPhantomWallet(),
        // getSlopeWallet(),
        // getSolflareWallet(),
        // getLedgerWallet(),
        // getSolletWallet({ network }),
        // getSolletExtensionWallet({ network }),
    ], []);

    useEffect(() => {
        if (network !== null) localStorage.setItem("MM-default-network", network);
    }, [network])

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    { children }  
                </WalletModalProvider>
            
                {/* <div
                    style={{
                        position: "fixed",
                        left: "0px",
                        bottom: "0px",
                        margin: "10px",
                        backgroundColor: "white",
                        fontSize: "10px"
                    }}
                >
                    <DropdownButton id="dropdown-basic-button" title={network} variant="dark">
                        <Dropdown.Item onClick={() => setNetwork(MAINNET1)}>Mainnet 1</Dropdown.Item>
                        <Dropdown.Item onClick={() => setNetwork(MAINNET2)}>Mainnet 2</Dropdown.Item>
                        <Dropdown.Item onClick={() => setNetwork(TESTNET)}>Testnet</Dropdown.Item>
                        <Dropdown.Item onClick={() => setNetwork(DEVNET)}>Devnet</Dropdown.Item>
                        <Dropdown.Item onClick={() => setNetwork(LOCALHOST)}>Local</Dropdown.Item>
                    </DropdownButton>
                </div> */}

            </WalletProvider>
        </ConnectionProvider>
    );
}
import React from 'react';

import {Container, Button} from 'react-bootstrap';

import '../styles/card-action.css';
import '../styles/redeem-nft.css';

export const ClaimNft = (props) => {
    // Define the action name
    const action = "ClaimNft";
    
    return (
        <Container className="card-action card-action-box vote-reserve-price">
            <div className="card-action card-action-title">CLAIM YOUR NFT</div>
            <div className="card-action card-action-body redeem">
                <div className="card-action card-action-body-img">
                    <img
                    alt=""
                    src="/nft.png"
                    width="60"
                    height="60"
                    />
                </div>
                <div className="card-action card-action-footer">
                    <div className="error-box">
                        {props.error && props.error.message ? props.error.message : ""}
                    </div>
                    <div><Button className="card-action-button" type="button" variant="primary" onClick={(e) => props.actionHandler(e, {action: action, data:null})}>CLAIM</Button></div>
                </div>
            </div>
        </Container>
    );
}
import React, {useState, useMemo} from 'react';

import {Container, Button, InputGroup, FormControl} from 'react-bootstrap';
import Countdown from './Countdown';

import * as utils from './utils';

import '../styles/card-action.css';
import '../styles/bidding.css';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { samePublicKey } from '../programs/utils';

const topBidderDataFunc = (value) => {
    if (value === null) return null;
    if (value) return ["You are the highest bidder!", "#1da168"];
    else return ["You are not the highest bidder!", "#9e0b18"];
}

export const Bidding = (props) => {
    // Define the action name
    const action = "Bidding";

    // Use state hoocks to store the input values
    const [data , setInputField] = useState({
        bid: ''
    });

    // Manage the input handlers
    const inputsHandler = (e) =>{
        // From target get the id and value
        const { id, value } = e.target;
        // Update the state
        setInputField((prevState) => ({
         ...prevState,
         [id]: value,
       }));
    }

    const endAt = useMemo(() => {
        if (utils.missing(props.auctionData)) return null;
        return props.auctionData.endTimestamp.toNumber();
    }, [props.auctionData])

    const topBidderData = useMemo(() => {
        if (utils.missing(props.auctionData, props.user)) return null;
        return topBidderDataFunc(samePublicKey(props.auctionData.topBidder, props.user))
    }, [props.auctionData, props.user]);
    const hasStarted = useMemo(() => !utils.missing(props.auctionData), [props.auctionData]);
    const title = useMemo(() => utils.missing(props.auctionData) ? "START AUCTION" : "BIDDING", [props.auctionData]);
    const currentTopBid = useMemo(() => !utils.missing(props.auctionData) ? (props.auctionData.topBid.toNumber() / LAMPORTS_PER_SOL).toFixed(2) : 0, [props.auctionData]);
    const currentTopBidder = useMemo(() => {
        if (!hasStarted) return null;
        let address = props.auctionData.topBidder.toString();
        return `${address.slice(0, 3)}...${address.slice(41)}`;
    }, [props.auctionData, hasStarted]);

    return (
        <Container className="card-action card-action-box vote-reserve-price">
            <div id="bidding" className="card-action card-action-title timer-box-container">
                <div className="timer-box">&nbsp;</div>
                <div className="timer-title-box">{ title }</div>
                <div className="timer-box color-danger"> { hasStarted && <Countdown endAt={endAt} /> }</div>
            </div>
            <div className="card-action card-action-body bidding">
                { topBidderData !== null && props.auctionData !== null && <div className="card-action card-action-body-title"><a className="top-bidder-text" href="..." style={{color: topBidderData[1]}}> { topBidderData[0] } </a></div> }
                <div className="card-action card-action-body-title">
                    { hasStarted && <div>HIGHEST BID: { currentTopBid } SOL { hasStarted ? "MADE BY" : null } <u>{ currentTopBidder }</u></div> }
                </div>
                <div className="card-action card-action-body-inputs center">
                    <div className="card-action input nes-field small-label full-width">
                        <label className="card-action-input-label" htmlFor="input_reserve_price">WALLET BALANCE: { props.solanaBalance } SOL</label>
                        <InputGroup className="card-action-input-group">
                            <FormControl
                                id="bid" 
                                className="card-action-input bidding"
                                placeholder=""
                                aria-label="INPUT DESIRED PRICE"
                                aria-describedby="bid-addon"
                                onChange={inputsHandler}
                                value={data.bid} 
                                type="number" 
                                onWheel={(e) => e.target.blur()} />
                            <InputGroup.Text 
                                id="bid-addon"
                                className="card-action-input-addon">
                                SOL
                            </InputGroup.Text>
                        </InputGroup>
                        {/* <label className="card-action-input-label">TOTAL NEW BET AMOUNT: 4200 SOL</label> */}
                    </div>
                </div>
                <div className="card-action card-action-footer">
                <div className="error-box">
                        {props.error && props.error.message ? props.error.message : ""}
                    </div>
                    <div><Button className="card-action-button" type="button" variant="primary" onClick={(e) => props.actionHandler(e, {action: action, data:data})}>PLACE BID</Button></div>
                </div>
            </div>
        </Container>
    );
}
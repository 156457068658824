import React, { useMemo } from 'react';
import { Container, Button } from 'react-bootstrap';

import * as utils from './utils';

import '../styles/card-action.css';
import '../styles/withdraw-bet.css';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';

export const WithdrawBid = (props) => {

    // Define the action name
    const action = "WithdrawBid";


    const bid = useMemo(() => {
        if (utils.missing(props.bid)) return null;
        return (props.bid.amount.toNumber() / LAMPORTS_PER_SOL).toFixed(2);
    }, [props.bid]);

    return (
        <Container className="card-action card-action-box vote-reserve-price">
            <div className="card-action card-action-title">WITHDRAW BID</div>
            <div className="card-action card-action-body bidding">
                { bid !== null && <div className="card-action card-action-body-title">YOUR EXISTING BID: { bid } SOL</div> }
                <div className="card-action card-action-footer">
                    <div className="error-box">
                        {props.error && props.error.message ? props.error.message : ""}
                    </div>
                    <div><Button className="card-action-button" type="button" variant="primary" onClick={(e) => props.actionHandler(e, {action: action, data: null})}>WITHDRAW</Button></div>
                </div>
            </div>
        </Container>
    );
}
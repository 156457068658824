import React, { useState, useMemo } from 'react';
import {Container, Button, InputGroup, FormControl} from 'react-bootstrap';

import * as utils from './utils';

import '../styles/card-action.css';
import '../styles/withdraw-token.css';

export const WithdrawVotes = (props) => {
    // Define the action name
    const action = "WithdrawVotes";
    
    // Use state hoocks to store the input values
    const [data , setInputField] = useState({
        withdraw_amount: '',
    });

    // Manage the input handlers
    const inputsHandler = (e) =>{
        // From target get the id and value
        const { id, value } = e.target;
        // Update the state
        setInputField((prevState) => ({
         ...prevState,
         [id]: value,
       }));
    }

    const votes = useMemo(() => {
        if (utils.missing(props.votes)) return 0;
        return props.votes;
    }, [props.votes])

    const price = useMemo(() => {
        if (utils.missing(props.price, props.supply)) return 0;
        return (props.price * props.supply).toFixed(2);
    }, [props.price, props.supply])
    
    return (
        <Container className="card-action card-action-box vote-reserve-price">
            <div className="card-action card-action-title">WITHDRAW TOKENS FROM VOTING VAULT</div>
            <div className="card-action card-action-body vote-reserve-price">
                <div className="card-action card-action-body-title">YOUR VOTES: { votes } MIMO</div>
                { votes !== 0 && <div className="card-action card-action-body-title">YOUR PRICE: { price } SOL</div> }
                <div className="card-action card-action-body-inputs center">
                    <div className="card-action input nes-field small-label">
                        <label className="card-action-input-label" htmlFor="input_reserve_price">WALLET BALANCE: { props.balance } MIMO</label>
                        <InputGroup className="card-action-input-group">
                            <FormControl
                                id="withdraw_amount" 
                                className="card-action-input vote-reserve-price"
                                placeholder=""
                                aria-label="INPUT DESIRED PRICE"
                                aria-describedby="wallet_balance-addon"
                                onChange={inputsHandler}
                                value={data.withdraw_amount} 
                                type="number"
                                onWheel={(e) => e.target.blur()} />
                            <InputGroup.Text 
                                id="wallet_balance-addon"
                                className="card-action-input-addon">
                                MIMO
                            </InputGroup.Text>
                        </InputGroup>
                    </div>
                </div>
                <div className="card-action card-action-footer">
                    <div className="error-box">
                        {props.error && props.error.message ? props.error.message : ""}
                    </div>
                    <div><Button className="card-action-button" type="button" variant="primary" onClick={(e) => props.actionHandler(e, {action: action, data: data})}>WITHDRAW</Button></div>
                </div>
            </div>
        </Container>
    );
}
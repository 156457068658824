import { useCallback, useState } from 'react';

import * as fetchingUtils from '../programs/fetching';

const useAuctionFetching = (vault, settings) => {
  
    const [solanaBalance, setSolanaBalance] = useState(0);
    const [fractionBalance, setFractionBalance] = useState(0);
    const [redeemableBalance, setRedeemableBalance] = useState(0);
    const [marketPrice, setMarketPrice] = useState({ SOL: 0, MIMO: 0 });
    const [vaultData, setVaultData] = useState(null);
    const [auction, setAuction] = useState(null);
    const [votingData, setVotingData] = useState(null);
    const [userData, setUserData] = useState(null);

    const refresh = useCallback((connection, publicKey) => {

        // Fetch the market price of MIMO
        fetchingUtils.getMarketPrice().then(setMarketPrice).catch(console.log);

        // Fetch the the vault data
        fetchingUtils.getVaultData(connection, vault).then(vaultData => {

            // If we cannot even fetch the vault let's stop here!
            if (vaultData === null) return;
            setVaultData(vaultData);

            console.log("WALLET", publicKey)
            // Fetch voting data
            fetchingUtils.getVotingData(connection, vaultData, publicKey).then(x => {
                console.log(x)
                setVotingData(x);
            }).catch(console.log);

            // Fetch auction data
            fetchingUtils.getAuctionData(connection, vaultData.publicKey, settings).then(auction => {
                setAuction(auction);
                fetchingUtils.getRedeemableBalance(connection, vaultData, auction.data).then(setRedeemableBalance).catch(console.log);
            }).catch(console.log);

            // Fetch user specific data
            if (publicKey !== null) {
                fetchingUtils.getTokenBalance(connection, vaultData.fractionMint, publicKey).then(setFractionBalance).catch(console.log);
                fetchingUtils.getUserData(connection, vaultData, publicKey).then(userData => {
                    setSolanaBalance(userData.balance);
                    setUserData(userData);
                }).catch(console.log);
            }

        }).catch(console.log);
    },  [vault, settings]);

    return [
        solanaBalance,
        fractionBalance,
        redeemableBalance,
        marketPrice,
        vaultData,
        auction,
        votingData,
        userData,
        refresh
    ];
}

export default useAuctionFetching;
import { PublicKey } from "@solana/web3.js";
import { useMemo } from "react";
import { useParams } from "react-router";

import * as programUtils from '../programs/utils';

const useVault = () => {
    const params = useParams();
    const vault = useMemo(() => {
        if (!programUtils.isPublicKey(params.vault)) return programUtils.MIMO_VAULT;
        return new PublicKey(params.vault);
    }, [params.vault]);
    return vault;
}

export default useVault;
import * as utils from '../utils';
import * as auctionUtils from './utils';
import * as vaultUtils from '../vault/utils';
import { Transaction, SYSVAR_RENT_PUBKEY } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { BN } from 'bn.js';

export const claim = async (connection, user, info) => {
    return await utils.solanaAction(connection, 'claim', async () => {

        const bidder = user.publicKey;
        const auctionData = info.auction.data;
        if (auctionData === null) return utils.failure("The auction hasn't started let alone finished!");

        const currentTimestamp = Math.floor(Date.now() / 1000);
        if (currentTimestamp < auctionData.endTimestamp.toNumber()) return utils.failure("The auction has not finished!");

        if (!utils.samePublicKey(auctionData.topBidder, bidder)) return utils.failure("Only the auction winner can claim");

        const program = auctionUtils.auctionProgram(connection, window.solana);
        const [authority, ] = info.auction.authority;
        const [auction, ] = info.auction.pda;
        const [vaultPda, ] = info.vault.pda;
        const settings = auctionData.settings;
        const fractionMint = info.vault.fractionMint;
        const tokenInfo = info.price.tokenInfo;
        const lockedMint = tokenInfo.lockedMint;
        const vault = info.vault.publicKey;
        const [safetyDepositBox, ] = info.vault.safetyDepositBox;
        let lockedTokenAccount = info.vault.lockedTokenAccount

        const transaction = new Transaction();
        const signers = [];

        const [destinationTokenAccount, hasATA, ] = await utils.associatedTokenAccount(connection, bidder, lockedMint);
        if (!hasATA) await utils.addCreateATAInstruction(transaction, bidder, lockedMint, bidder);

        const nftAmount = new BN(1);
        transaction.add(
            program.instruction.claim(nftAmount, {
                accounts: {
                    authority,
                    auction,
                    settings,
                    vault,
                    destinationTokenAccount,
                    bidder,
                    tokenProgram: TOKEN_PROGRAM_ID,
                    lockedTokenAccount,
                    safetyDepositBox,
                    vaultPda,
                    vaultProgram: vaultUtils.VAULT_PROGRAM_ID,
                    fractionMint,
                    rent: SYSVAR_RENT_PUBKEY
                }
            })
        );

        return [transaction, signers];

    });

}
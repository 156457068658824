import * as utils from '../utils';
import * as auctionUtils from './utils';
import { Transaction, SYSVAR_RENT_PUBKEY, SystemProgram } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID, Token, u64 } from '@solana/spl-token';
import { VAULT_PROGRAM_ID } from '../vault/utils';

export const redeemFractions = async (connection, user, info, amount) => {
    return await utils.solanaAction(connection, 'redeem_fractions', async () => {
        if (!utils.isValidNumber(amount)) return utils.failure("Invalid redeem amount");
        if (info.auction.data === null) return utils.failure("The auction has not started let alone finished!");

        const currentTimestamp = Math.floor(Date.now() / 1000);
        if (currentTimestamp < info.auction.data.endTimestamp.toNumber()) return utils.failure("The auction has not finished!");

        const program = auctionUtils.auctionProgram(connection, window.solana);

        const [auction, ] = info.auction.pda;
        const auctionData = info.auction.data;
        const settings = auctionData.settings;
        const paymentTreasury = auctionData.paymentTreasury;
        const redeemTreasury = info.vault.redeemTreasury;
        const fractionMint = info.vault.fractionMint;
        const [vaultPda, ] = info.vault.pda;
        const redeemer = user.publicKey;

        const transaction = new Transaction();
        const signers = [];

         // Check the user has fractions in their ATA
         if (!user.hasFractionATA) return utils.failure("User doesn't have any fractions");

         // Check the user has enough fractions in their ATA
         if (user.fractionATABalance < amount) return utils.failure("User doesn't have enough fractions");

         const userTokenAccount = user.fractionATA;

        const balanceNeeded = user.tokenAccountRentExemptBalance;
        const destinationTokenAccount = utils.addCreateNativeTokenAccountInstructionSync(transaction, signers, redeemer, redeemer, balanceNeeded, 0);
        const sourceTokenAccount = utils.addCreateTokenAccountInstructionSync(transaction, signers, redeemer, redeemer, fractionMint, balanceNeeded);

        amount = new u64((amount * 1e9).toString());
        transaction.add(Token.createTransferInstruction(TOKEN_PROGRAM_ID, userTokenAccount, sourceTokenAccount, redeemer, [], amount));
        
        transaction.add(
            program.instruction.redeem({
                accounts: {
                    auction,
                    settings,
                    vault: info.vault.publicKey,
                    redeemTreasury,
                    paymentTreasury,
                    destinationTokenAccount,
                    sourceTokenAccount,
                    redeemer,
                    vaultPda,
                    vaultProgram: VAULT_PROGRAM_ID,
                    fractionMint,
                    tokenProgram: TOKEN_PROGRAM_ID,
                    rent: SYSVAR_RENT_PUBKEY,
                    systemProgram: SystemProgram.programId,
                }
            })
        )

        // close tmp fraction account

        utils.addCloseTokenAccountInstruction(transaction, destinationTokenAccount, redeemer);
        utils.addCloseTokenAccountInstruction(transaction, sourceTokenAccount, redeemer);

        return [transaction, signers];

    });

}

import React from 'react';

const MobileIcon = ({ href, name, className }) => {
    return <a className={` ${className}`} href={href} target="_blank" width="50rem" rel="noreferrer"><img src={`/social_icons_8bit/${name}.png`} alt={className} width="40rem"></img></a>
}

export const FooterMobile = () => {

    return (
        <div style={{margin: '10%'}}>
            <div className="footer-mobile">
                <MobileIcon href="https://docs.millionmonke.com/" name="GitBook" className="gitbook" />
                <MobileIcon href="https://twitter.com/MeltLabs" name="Twitter" className="twitter" />
                <MobileIcon href="https://discord.com/invite/hjmxRnGw7h" name="Discord" className="discord" />
                <MobileIcon href="https://medium.com/millionmonke" name="Medium" className="medium" />
                <MobileIcon href="mailto:info@millionmonke.com" name="Mail" className="mail" />
                <MobileIcon href="https://pfp.millionmonke.com/" name="pfp" className="pfp" />
            </div>
        </div>
        
    )
}
import { PublicKey } from "@solana/web3.js";
import { useMemo } from "react";
import { useParams } from "react-router";

import * as programUtils from '../programs/utils';

const useSettings = () => {
    const params = useParams();
    const settings = useMemo(() => {
        if (!programUtils.isPublicKey(params.settings)) return programUtils.DEFAULT_SETTINGS;
        return new PublicKey(params.settings);
    }, [params.settings]);
    return settings;
}

export default useSettings;
import React, { useState, useEffect, useCallback } from 'react';
import {Navbar, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/navbar.css';
import { isBrowser } from 'react-device-detect';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import { Button } from '@solana/wallet-adapter-react-ui/lib/Button';

require('@solana/wallet-adapter-react-ui/styles.css');

export const WalletButton = () => {

    const { setVisible } = useWalletModal();
    const { connected, disconnect, publicKey } = useWallet();
    const [walletString, setWalletString] = useState(null)

    useEffect(() => {
        if (publicKey === null) {
            setWalletString(null);
            return;
        }
        let base58 = publicKey.toBase58();
        setWalletString(base58.slice(0, 4) + '..' + base58.slice(-4));
    }, [publicKey, setWalletString])

    const clickHandler = useCallback(() => {
        if (!connected) setVisible(true);
        else disconnect();
    }, [connected, setVisible, disconnect])
    
    return (
        <Button 
            style={{
                width: "280px", 
                height: "40px",
                backgroundColor: "#dc3545", 
                fontFamily: "'Press Start 2P', cursive",
                fontSize: "15px"
            }}
            onClick={clickHandler}
        > 
            {walletString === null ? "Connect Wallet" : walletString} 
        </Button>
        
    )
}

export const Header = () => {
    return (
        <Navbar className="top-navbar" variant="dark">
            <Container>
                <Navbar.Brand>
                    <img
                    alt=""
                    src="/nft.png"
                    width="40"
                    height="40"
                    className="d-inline-block align-bottom"
                    />{' '}
                Million Monke
                </Navbar.Brand>
                { isBrowser && <WalletButton /> }
            </Container>
        </Navbar>
    )
}
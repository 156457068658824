import ReactGA from "react-ga4";
import { Container } from "react-bootstrap";
import React, {useEffect } from 'react';
import { If, Then, Else } from 'react-if';
import { isBrowser} from 'react-device-detect';

import { Footer } from '../components/Footer';
import { FooterMobile } from '../components/FooterMobile';
import { Header } from '../components/Navbar';
import { Description } from "../components/Description";
import { VoteReservePrice } from '../components/VoteReservePrice';
import { WithdrawVotes } from '../components/WithdrawVotes';
import { Bidding } from '../components/Bidding';
import { WithdrawBid } from '../components/WithdrawBid';
import { RedeemFractions } from '../components/RedeemFractions';
import { ClaimNft } from "../components/ClaimNft";

import '../styles/nft-page.css';
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { addVotes } from "../programs/price/add_votes";
import { removeVotes } from "../programs/price/remove_votes";
import { bid, redeem } from "../programs/auction/utils";
import { withdrawBid } from "../programs/auction/withdraw_bid";
import { claim } from "../programs/auction/claim";
import { errorPopup, transactionPopup } from "../notifications";
import useAuctionFetching from "../hooks/useAuctionFetching";
import * as constants from "../utils/constants";
import * as utils from '../utils/utils';
import * as componentUtils from '../components/utils';
import useSettings from "../hooks/useSettings";
import useVault from "../hooks/useVault";

export const NFTPage = () => {

    // Setup analytics
    ReactGA.initialize("G-R7NREQXFB2");
    ReactGA.send({ hitType: "pageview", page: "/mimo" });

    // Solana connection
    const { connection } = useConnection();

    // Connected wallet
    const { publicKey } = useWallet();

    // Vault to be auctioned
    const vault = useVault();

    // Auction settings
    const settings = useSettings();

    const [
        solanaBalance,
        fractionBalance,
        redeemableBalance,
        marketPrice,
        vaultData,
        auction,
        votingData,
        userData,
        refresh
    ] = useAuctionFetching(vault, settings);

    // Fetch data whenever solana connection or connected wallet changes
    useEffect(() => {
        refresh(connection, publicKey); 
    }, [connection, publicKey, refresh]);

    // Fetch the data every 60 seconds to make sure the information is up-to-date!
    useEffect(() => {
        const id = setInterval(() => refresh(connection, publicKey), 20_000);
        return () => clearInterval(id);
     }, [connection, publicKey, refresh]);

    // Handle button action
    const actionHandler = async (e, data) => {
        console.log("AUCTION@actionHandler:", data);
        let [success, result] = [false, null];

        // No actions can be performed if the wallet is not connected
        if (componentUtils.missing(publicKey)) {
            errorPopup("Wallet is not connected!");
            return;
        }

        // If any of the following are missing, we may not have the data necessary for carrying
        // out the desired solana transaction. So just notify the user and wait a moment.
        if (componentUtils.missing(userData, vaultData, votingData, auction)) {
            console.log("NOTICE: data is still loading.");
            errorPopup("Some blockchain data is still loading. Try again in a few seconds!");
            return;
        }
        
        // Consolidate data to pass to the desired action.
        const general = {
            vault: vaultData,
            price: votingData,
            auction,
        }

        switch (data.action) {
            case "VoteReservePrice": 
                [success, result] = await addVotes(connection, userData, general, data.data.input_reserve_mimo, data.data.input_reserve_price);
                break;
            case "WithdrawVotes":   
                [success, result] = await removeVotes(connection, userData, general, data.data.withdraw_amount);
                break;
            case "Bidding":
                [success, result] = await bid(connection, userData, general, data.data.bid, settings);
                break;
            case "WithdrawBid":
                [success, result] = await withdrawBid(connection, userData, general);
                break;
            case "RedeemFractions":
                [success, result] = await redeem(connection, userData, general, data.data.redeem_amount);
                break;
            case "ClaimNft":
                [success, result] = await claim(connection, userData, general);
                break;
            default:
                break;
        }
        
        if (success) transactionPopup(result)
        else errorPopup(result);

        // Update the page data!
        refresh(connection, publicKey);
        
    }

    
    return (
        <div className="nft-page">
            <If condition={isBrowser}>
                <Then>
                    <Header />
                    <span className="nes-text smb-text medium-font">SMB #3408</span>
                    <img alt="" className="smb-img" src="/nft.png"></img>
                    <div className="buttons nft-box-buttons">
                        <button type="button" className="nes-btn is-warning vote-reserve text-black" onClick={() => utils.scrollTo('vote-reserve-price')}>VOTE RESERVE</button>
                        <button type="button" className="nes-btn is-primary bid-for-nft text-black" onClick={() => utils.scrollTo('bidding')}>BID FOR NFT</button>
                        <button type="button" className="nes-btn is-success buy-on-raydium text-black" onClick={() => window.open(constants.RAYDIUM_MIMO_SWAP_URL, '_blank')}>BUY ON RAYDIUM</button>
                    </div>
                    <Description 
                        supply={vaultData?.fractionSupply}
                        totalVotes={votingData?.totalVotes}
                        marketPrice={marketPrice} 
                    />
                    <Container className="card-action-container">    
                        <VoteReservePrice 
                            actionHandler={actionHandler} 
                            balance={fractionBalance}
                            reserve={votingData?.reservePrice}
                            facilitatorFee={auction?.settings?.facilitatorFee}
                        />
                        <WithdrawVotes 
                            actionHandler={actionHandler} 
                            balance={fractionBalance}
                            votes={votingData?.userVotes}
                            price={votingData?.userPrice}
                            supply={vaultData?.fractionSupply}
                        />
                        <Bidding 
                            actionHandler={actionHandler} 
                            solanaBalance={solanaBalance}
                            auctionData={auction?.data}
                            user={publicKey}
                        />
                        <WithdrawBid 
                            actionHandler={actionHandler} 
                            bid={userData?.bid}
                        />
                        <RedeemFractions 
                            actionHandler={actionHandler} 
                            balance={fractionBalance}
                            redeemableBalance={redeemableBalance}
                        />
                        <ClaimNft actionHandler={actionHandler} />
                    </Container>
                    <Footer />
                </Then>
                <Else>
                    <Header />
                    <span className="nes-text smb-text medium-font">SMB #3408</span>
                    <img alt="" className="smb-img space-div" src="/nft.png"></img>
                    <Description 
                        supply={vaultData?.fractionSupply}
                        totalVotes={votingData?.totalVotes}
                        marketPrice={marketPrice} 
                    />
                    <FooterMobile />
                </Else>
            </If>
        </div>
    )
}
import { auctionIDL, auctionProgramID } from './idl';
import { Provider, Program } from '@project-serum/anchor';
import { PublicKey } from '@solana/web3.js';
import { startAuction } from './start';
import { placeBid } from './place_bid';
import { end } from './end';
import { redeemFractions } from './redeem';
import * as programUtils from '../utils';

export const MAX_FACILITATOR_FEE = 1_000_000_000;

export const auctionProgram = (connection, wallet) => {
    const provider = new Provider(connection, wallet, "processed");
    return new Program(auctionIDL, auctionProgramID, provider);
}

export const findAuthorityAccount = async (program) => {
    return await PublicKey.findProgramAddress(
        [Buffer.from("authority"), program.programId.toBuffer()], 
        program.programId
    );
}

export const findAuctionAccount = async (program, vaultAccount) => {
	return await PublicKey.findProgramAddress(
		[Buffer.from("auction"), vaultAccount.toBuffer()], 
		program.programId
	);
};

export const findBidAccount = async (program, auctionAccount, bidder) => {
	return await PublicKey.findProgramAddress(
		[Buffer.from("bid"), bidder.toBuffer(), auctionAccount.toBuffer()], 
		program.programId
	);
};

export const getAuctionData = async (connection, vault) => {
    const program = auctionProgram(connection, window.solana);
    try {
        const [address, ] = await findAuctionAccount(program, vault);
        return await program.account.auction.fetch(address);
    } catch (err) {
        return null;
    }
}

export const getBidData = async (program, bid) => {
    try { return await program.account.bid.fetch(bid) } 
    catch { return null }
}

export const fetchAuctionData = async (program, account) => {
    try { return await program.account.auction.fetch(account) } 
    catch { return null }
}

export const fetchBidData = async (program, account) => {
    try { return await program.account.bid.fetch(account) } 
    catch { return null }
}

export const fetchAuthorityData = async (program, account) => {
    try { return await program.account.authority.fetch(account) } 
    catch { return null }
}

export const fetchSettingsData = async (program, account) => {
    try { return await program.account.settings.fetch(account) } 
    catch { return null }
}

export const bid = async (connection, bidder, info, bidAmount, settings) => {
    if (info.auction.data === null) return await startAuction(connection, bidder, info, bidAmount, settings);
    else return await placeBid(connection, bidder, info, bidAmount);
} 

export const redeem = async (connection, user, info, amount) => {
    if (info.auction.data === null) return programUtils.failure("The auction hasn't started let alone finished!");

    const currentTimestamp = Math.floor(Date.now() / 1000);
    if (currentTimestamp < info.auction.data.endTimestamp.toNumber()) return programUtils.failure("The auction has not finished!");

    if (!programUtils.isValidNumber(amount)) return programUtils.failure("Invalid redeem amount");
    if (user.fractionATABalance < amount) return programUtils.failure("User doesn't have enough fractions");

    if (!info.auction.data.feePaid) {
        let [success, message] = await end(connection, user, info);
        if (!success) return [success, message];
    }
    return await redeemFractions(connection, user, info, amount);
}
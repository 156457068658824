import { SystemProgram, SYSVAR_RENT_PUBKEY, Transaction } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import * as priceUtils from './utils';
import * as utils from '../utils';
import { BN } from 'bn.js';

export const addVotes = async (connection, user, info, votes, price) => {
    return await utils.solanaAction(connection, 'add_votes', async () => {

        // Since we aren't really doing any input validation:
        // Make sure the input for number of votes is correct!
        if (!utils.isValidNumber(votes)) return utils.failure("Invalid number of votes");

        // Make sure the input for the desired price is correct!
        if (!utils.isValidNumber(price)) return utils.failure("Invalid price");

        const program = priceUtils.priceProgram(connection, window.solana);
        const priceAccount = info.vault.priceAccount;
        const storeMint = info.vault.fractionMint;
        const [userPda, userPdaBump] = user.pricePDA;
        const [storeAuthorityPda, storeAuthorityPdaBump] = info.price.authority;
        const tokenInfo = info.price.tokenInfo;
        const store = tokenInfo.store;
        const weightedPrice = info.vault.priceAccountData.pricePerShare;
        const MULTIPLIER = new BN(5);

        // Check the user has fractions in their ATA
        if (!user.hasFractionATA) return utils.failure("User doesn't have any fractions");

        // Check the user has enough fractions in their ATA
        if (user.fractionATABalance < votes) return utils.failure("User doesn't have enough fractions");

        // Convert the instructions parameters to 64 bit integers!
        votes = utils.toBN(votes);
        price = utils.toBN(price / info.vault.fractionSupply);

        // Check some constraints regarding price
        if (tokenInfo.minPrice.gt(price)) return utils.failure("Reserve price cannot be set that low");
        if (tokenInfo.totalVotes.toString() !== "0") {
            if (price.gt(weightedPrice.mul(MULTIPLIER))) return utils.failure("Cannot set price more than 5x the current reserve price");
            if (price.lt(weightedPrice.div(MULTIPLIER))) return utils.failure("Cannot set price less than 1/5th the current reserve price");
        }

        const transaction = new Transaction();
        const signers = [];

        transaction.add(
            program.instruction.addVote(storeAuthorityPdaBump, userPdaBump, votes, price, {
                accounts: {
                    userPda,
                    priceAccount,
                    storeMint,
                    userTokenAccount: user.fractionATA,
                    store,
                    storeAuthorityPda,
                    signer: user.publicKey,
                    tokenProgram: TOKEN_PROGRAM_ID,
                    systemProgram: SystemProgram.programId,
                    rent: SYSVAR_RENT_PUBKEY,
                }
            })
        );

        return [transaction, signers];

    });
}

import { store } from "react-notifications-component";

const explorerLink = (signature) => {
    return `https://solscan.io/tx/${signature}`;
}

const transactionText = (signature) => {
    return <>
        See it {" "}
        <a className="transaction-text" target="_blank" rel="noreferrer" href={explorerLink(signature)}> here </a>
    </>
}

export const transactionPopup = (signature) => {
    store.addNotification({
        title: "Transaction sent!",
        message: transactionText(signature),
        type: "info",
        insert: "top",
        container: "bottom-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 5000,
            onScreen: false
        },
    });
}

export const errorPopup = (message) => {
    store.addNotification({
        title: "Error Occurred:",
        message: (<p style={{color: 'white'}}>{message}</p>),
        type: "danger",
        insert: "top",
        container: "bottom-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 5000,
            onScreen: false
        },
    });
}
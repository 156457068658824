import { Token, TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID, NATIVE_MINT, AccountLayout } from '@solana/spl-token';
import { Keypair, SystemProgram, PublicKey, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { BN, Provider } from '@project-serum/anchor';
import { decodeSafetyDepositBox, decodeVault } from './vault/schema';
import { getAuctionData } from './auction/utils';
import * as priceUtils from './price/utils';
import * as auctionUtils from './auction/utils';
import * as vaultUtils from './vault/utils';


export const MIMO_VAULT = new PublicKey("4j5GLcQYJBve9Mn8bx73qugQ2mAR1XaLaxd9Q9zqx6tg");
export const DEFAULT_SETTINGS = new PublicKey("7quA6niYuhqw21gXxTHqCXZ2s7bYc8TDEFtrS2VJxAjp");

export const addCreateNativeTokenAccountInstruction = async (provider, transaction, signers, user, owner, amount) => {
    return await addCreateTokenAccountInstruction(provider, transaction, signers, user, owner, NATIVE_MINT, amount);
}

export const addCreateTokenAccountInstruction = async (provider, transaction, signers, user, owner, mint, amount=0) => {
    let balanceNeeded = await Token.getMinBalanceRentForExemptAccount(provider.connection);
	let newAccount = Keypair.generate();
    amount = toBN(amount).toNumber();
	transaction.add(
		SystemProgram.createAccount({
			fromPubkey: user,
			newAccountPubkey: newAccount.publicKey,
			lamports: balanceNeeded + amount,
			space: AccountLayout.span,
			programId: TOKEN_PROGRAM_ID,
		}),
		Token.createInitAccountInstruction(
			TOKEN_PROGRAM_ID,
			mint,
			newAccount.publicKey,
			owner,
		)
	);
    signers.push(newAccount);
	return newAccount.publicKey;
}

export const addCreateNativeTokenAccountInstructionSync = (transaction, signers, user, owner, balanceNeeded, amount) => {
    return addCreateTokenAccountInstructionSync(transaction, signers, user, owner, NATIVE_MINT, balanceNeeded, amount);
}

export const addCreateTokenAccountInstructionSync = (transaction, signers, user, owner, mint, balanceNeeded, amount=0) => {
	let newAccount = Keypair.generate();
    amount = toBN(amount).toNumber();
	transaction.add(
		SystemProgram.createAccount({
			fromPubkey: user,
			newAccountPubkey: newAccount.publicKey,
			lamports: balanceNeeded + amount,
			space: AccountLayout.span,
			programId: TOKEN_PROGRAM_ID,
		}),
		Token.createInitAccountInstruction(
			TOKEN_PROGRAM_ID,
			mint,
			newAccount.publicKey,
			owner,
		)
	);
    signers.push(newAccount);
	return newAccount.publicKey;
}


export const addCloseTokenAccountInstruction = (transaction, account, user) => {
    transaction.add(Token.createCloseAccountInstruction(
        TOKEN_PROGRAM_ID, // fixed
        account, // to be closed token account
        user, // rent's destination
        user, // token account authority
        []
    ));
}


export const getATA = async (user, mint) => {
    return await Token.getAssociatedTokenAddress(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        mint,
        user
    );
}

export const hasATA = async (connection, user, mint) => {
    let ata = await getATA(user, mint);
    const info = await connection.getAccountInfo(ata);
    if (info === null) return false; // FAILED_TO_FIND_ACCOUNT
    if (!info.owner.equals(TOKEN_PROGRAM_ID)) return false; // INVALID_ACCOUNT_OWNER
    return true;
}

export const addCreateATAInstruction = async (transaction, user, mint, payer) => {
    let ata = await getATA(user, mint);
    transaction.add(Token.createAssociatedTokenAccountInstruction(
        ASSOCIATED_TOKEN_PROGRAM_ID, // always associated token program id
        TOKEN_PROGRAM_ID, // always token program id
        mint, // mint (which we used to calculate ata)
        ata, // the ata we calcualted early
        user, // token account owner (which we used to calculate ata)
        payer // payer, fund account, like SystemProgram.createAccount's from
    ));
    return ata;
}

export const toBN = (amount, decimals=9) => {
    return new BN(Math.round(amount * 10**decimals).toString());
}

export const getTotalBalance = async (connection, vault, owner) => {
    const vaultData = await decodeVault(connection, vault);
    const mint = new PublicKey(vaultData.fractionMint);
    let accounts;
    try {
        accounts = await connection.getTokenAccountsByOwner(owner, { mint })
    } catch (err) { console.log(err); return 0 }
    let totalBalance = 0;
    for (let i = 0; i < accounts.value.length; i++) {
        totalBalance += (await connection.getTokenAccountBalance(accounts.value[i].pubkey)).value.uiAmount
    }
    return totalBalance.toLocaleString();
}



export const getFractionSupply = async (connection, vault) => {
    const vaultData = await decodeVault(connection, vault);
    const mint = new PublicKey(vaultData.fractionMint);
    let token = new Token(connection, mint, TOKEN_PROGRAM_ID, null);
    let mintData = await token.getMintInfo();
    return mintData.supply / new BN(10**mintData.decimals);
}

export const getHighestBalanceAccount = async (connection, tokenMint, owner) => {
    try {
        const accounts = await connection.getTokenAccountsByOwner(owner, { mint: tokenMint });
        let highestBalance = -1;
        let highestAccount = null;
        for (let i = 0; i < accounts.value.length; i++) {
            let balance = (await connection.getTokenAccountBalance(accounts.value[i].pubkey)).value.uiAmount
            if (balance > highestBalance) {
                highestBalance = balance;
                highestAccount = accounts.value[i].pubkey
            }
        }
        return [highestAccount, highestBalance];
    } catch (err) { 
        return null 
    }
}

export const getBalance = async (connection, user) => {
    return await connection.getBalance(user) / LAMPORTS_PER_SOL;
}

export const getRedeemableBalance = async (connection, vault) => {
    const vaultData = await decodeVault(connection, vault);
    const redeemTreasury = new PublicKey(vaultData.redeemTreasury);
    const auctionData = await getAuctionData(connection, vault);
    if (auctionData === null) return 0;
    const paymentTreasury = auctionData.paymentTreasury;
    const redeemTreasuryAmount = await getTokenAccountBalance(connection, redeemTreasury);
    const paymentTreasuryAmount = await getTokenAccountBalance(connection, paymentTreasury);
    // const currentTimestamp = new BN(Math.floor(Date.now() / 1000).toString());
    // if (currentTimestamp.lt(auctionData.endTimestamp)) return 0;
    return redeemTreasuryAmount + paymentTreasuryAmount;
}

export const getTokenAccountBalance = async (connection, account) => {
    try {
        const result = await connection.getTokenAccountBalance(account);
        return result.value.uiAmount;
    } catch {
        return 0;
    }
}

export const isValidNumber = (value) => {
    return !isNaN(value) && value !== ""
}

export const isPublicKey = (value) => {
    try {
        new PublicKey(value);
        return true;
    } catch {
        return false;
    }
}

export const getGeneralInfo = async (connection, vault, settings) => {

    // Fetch the vault data
    const vaultData = await decodeVault(connection, vault);
    const priceAccount = new PublicKey(vaultData.pricingLookupAddress);
    const fractionMint = new PublicKey(vaultData.fractionMint)
    const fractionTreasury = new PublicKey(vaultData.fractionTreasury);
    const redeemTreasury = new PublicKey(vaultData.redeemTreasury);
    const vaultPDA = await vaultUtils.findVaultPDA(vault);

    // Fetch the price data
    const priceProgram = priceUtils.priceProgram(connection, window.solana);
    const priceAuthority = await priceUtils.findStoreAuthority(priceProgram, priceAccount); 
    const [tokenInfo, priceAccountData, tokenAccountRentExemptBalance] = await Promise.all([
        priceProgram.account.tokenInfo.fetch(priceAuthority[0]),
        priceProgram.account.externalPriceAccount.fetch(priceAccount),
        Token.getMinBalanceRentForExemptAccount(connection)
    ]);

    // Safety box stuff
    const lockedMint = tokenInfo.lockedMint;
    const [safetyDepositBox, ] = await vaultUtils.findSafetyBox(vault, lockedMint);
    const safetyDepositBoxData = await decodeSafetyDepositBox(connection, vault, lockedMint);
    const lockedTokenAccount = new PublicKey(safetyDepositBoxData.store);

    // Fetch the auction data
    const auctionProgram = auctionUtils.auctionProgram(connection, window.solana);
    const [auctionAuthority, auctionPDA] = await Promise.all([
        auctionUtils.findAuthorityAccount(auctionProgram),
        auctionUtils.findAuctionAccount(auctionProgram, vault)
    ]);
    const auctionData = await auctionUtils.fetchAuctionData(auctionProgram, auctionPDA[0]);
    const auctionSettings = auctionData === null ? settings : auctionData.settings;
    const settingsData = await auctionUtils.fetchSettingsData(auctionProgram, auctionSettings);
    const authorityData = await auctionUtils.fetchAuthorityData(auctionProgram, auctionAuthority[0]);

    let fractionToken = new Token(connection, fractionMint, TOKEN_PROGRAM_ID, null);
    let fractionMintData = await fractionToken.getMintInfo();
    const fractionSupply = fractionMintData.supply.div(new BN(10**fractionMintData.decimals));

    const vaultInfo = {
        publicKey: vault,
        fractionMint,
        redeemTreasury,
        fractionTreasury,
        pda: vaultPDA,
        priceAccount,
        priceAccountData,
        lockedMint,
        safetyDepositBox,
        lockedTokenAccount,
        fractionSupply
    }

    const priceInfo = {
        authority: priceAuthority,
        tokenInfo,
    }

    const auctionInfo = {
        authority: auctionAuthority,
        pda: auctionPDA,
        data: auctionData,
        settings: settingsData,
        owner: authorityData?.owner
    }

    return {
        vault: vaultInfo,
        price: priceInfo,
        auction: auctionInfo,
        tokenAccountRentExemptBalance
    }

}

export const associatedTokenAccount = async (connection, user, mint) => {
    const ata = await getATA(user, mint);
    const info = await connection.getAccountInfo(ata);
    if (info === null) return [ata, false, 0]; // FAILED_TO_FIND_ACCOUNT
    if (!info.owner.equals(TOKEN_PROGRAM_ID)) return [ata, false, 0]; // INVALID_ACCOUNT_OWNER
    const balance = (await connection.getTokenAccountBalance(ata)).value.uiAmount;
    return [ata, true, balance];
}

export const getUserInfo = async (connection, vault, user) => {
    const vaultData = await decodeVault(connection, vault);
    const priceAccount = new PublicKey(vaultData.pricingLookupAddress);
    const fractionMint = new PublicKey(vaultData.fractionMint);

    const priceProgram = priceUtils.priceProgram(connection, window.solana);
    const pricePDA = await priceUtils.findUser(priceProgram, priceAccount, user);

    const auctionProgram = auctionUtils.auctionProgram(connection, window.solana);
    const auctionPDA = await auctionUtils.findAuctionAccount(auctionProgram, vault)
    const bidAccount = await auctionUtils.findBidAccount(auctionProgram, auctionPDA[0], user);
    const bidData = await auctionUtils.getBidData(auctionProgram, bidAccount[0]);

    let voteInfo;
    try {
        voteInfo = await priceProgram.account.userInfo.fetch(pricePDA[0]);
    } catch {
        voteInfo = null;
    }
    
    const [fractionATA, hasFractionATA, fractionATABalance] = await associatedTokenAccount(connection, user, fractionMint);
    const balance = await getBalance(connection, user);

    return {
        publicKey: user,
        pricePDA,
        fractionATA,
        hasFractionATA,
        fractionATABalance,
        voteInfo,
        bidAccount,
        bid: bidData,
        balance
    }
}

export const samePublicKey = (a, b) => {
    return a.toString() === b.toString();
}

export const success = (data) => {
    return [true, data];
}

export const failure = (message) => {
    return [false, message];
}

export const solanaAction = async (connection, name, action) => {
    try {

        // Start the action timer
        console.time(name);

        // Attempt to create the transaction
        const result = await action();

        // Log the transaction creation time
        console.timeEnd(name);

        // Return the error if creation failed
        if (!result[0]) return result;

        // Send the transaction
        const [transaction, signers] = result;
        const provider = new Provider(connection, window.solana, "processed");
        const sig = await provider.send(transaction, signers);
        return success(sig);

    } catch (err) {
        if (err.code === 4001) return failure("User rejected transaction");
        console.log("Unknown Error:\n==============\n", err, "\n==============");
        return failure("Unknown error");
    }
}


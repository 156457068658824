import { Transaction } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import * as priceUtils from './utils';
import * as utils from '../utils';

export const removeVotes = async (connection, user, info, votes) => {
    return await utils.solanaAction(connection, 'remove_votes', async () => {
        if (!utils.isValidNumber(votes)) return utils.failure("Votes must be a number");

        const voter = user.publicKey;
        const program = priceUtils.priceProgram(connection, window.solana);
        const priceAccount = info.vault.priceAccount;
        const storeMint = info.vault.fractionMint;
        const [userPda, userPdaBump] = user.pricePDA;
        const [storeAuthorityPda, storeAuthorityPdaBump] = info.price.authority;
        const tokenInfo = info.price.tokenInfo;
        const store = tokenInfo.store;

        votes = utils.toBN(votes);

        const userInfo = user.voteInfo;
        if (userInfo === null || userInfo.votes.lt(votes)) return utils.failure("You don't have enough fractions to withdraw");

        const transaction = new Transaction();
        const signers = [];
        
        if (!user.hasFractionATA) await utils.addCreateATAInstruction(transaction, voter, storeMint, voter);

        transaction.add(
            program.instruction.removeVote(storeAuthorityPdaBump, userPdaBump, votes, {
                accounts: {
                    userPda,
                    priceAccount,
                    storeMint,
                    userTokenAccount: user.fractionATA,
                    store,
                    storeAuthorityPda,
                    signer: voter,
                    tokenProgram: TOKEN_PROGRAM_ID,
                },
            })
        );

        return [transaction, signers];
    });
}


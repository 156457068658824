import { deserializeUnchecked } from 'borsh';
import { Token, TOKEN_PROGRAM_ID, u64 } from '@solana/spl-token';
import { LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import * as BN from 'bn.js';
import * as BD from 'js-big-decimal';

import * as schema from './vault/schema';
import * as vaultUtils from './vault/utils';
import * as votingUtils from './price/utils';
import * as auctionUtils from './auction/utils';
import * as programUtils from './utils';

const ONE_BN = new BN(LAMPORTS_PER_SOL);
const ONE = LAMPORTS_PER_SOL;

export const getRedeemableBalance = async (connection, vaultData, auctionData) => {
    if (auctionData === null) return 0;
    const paymentTreasury = auctionData.paymentTreasury;
    const [redeemTreasuryAmount, paymentTreasuryAmount] = await Promise.all([
        programUtils.getTokenAccountBalance(connection, vaultData.redeemTreasury),
        programUtils.getTokenAccountBalance(connection, paymentTreasury)
    ]);
    return redeemTreasuryAmount + paymentTreasuryAmount;
}

export const getVaultData = async (connection, address) => {
    let accountInfo = await connection.getAccountInfo(address);
    if (accountInfo === null) return null;

    const vaultData = deserializeUnchecked(schema.VAULT_SCHEMA, schema.Vault, accountInfo.data).data;
    const vaultPDA = await vaultUtils.findVaultPDA(address);
    const priceAccount = new PublicKey(vaultData.pricingLookupAddress);
    const fractionMint = new PublicKey(vaultData.fractionMint);
    
    const priceProgram = votingUtils.priceProgram(connection, window.solana);
    const storeAuthority = await votingUtils.findStoreAuthority(priceProgram, priceAccount);
    const tokenInfo = await priceProgram.account.tokenInfo.fetch(storeAuthority[0]);
    const lockedMint = tokenInfo.lockedMint;

    const [fractionSupply, priceAccountData, safetyDepositBoxData] = await Promise.all([
        getSupply(connection, fractionMint),
        priceProgram.account.externalPriceAccount.fetch(priceAccount),
        schema.decodeSafetyDepositBox(connection, address, lockedMint)
    ]);
    
    const safetyDepositBox = await vaultUtils.findSafetyBox(address, lockedMint);
    const lockedTokenAccount = new PublicKey(safetyDepositBoxData.store);

	return {
		publicKey: address,
        lockedMint,
		fractionMint,
        fractionSupply,
		priceAccount,
		fractionTreasury: new PublicKey(vaultData.fractionTreasury),
		redeemTreasury: new PublicKey(vaultData.redeemTreasury),
		pda: vaultPDA,
        safetyDepositBox,
        lockedTokenAccount,
        priceAccountData
	}
}

export const getSupply = async (connection, mint) => {
    const fractionToken = new Token(connection, mint, TOKEN_PROGRAM_ID, null);
    const mintData = await fractionToken.getMintInfo();
    const decimalDivisor = new u64(10 ** mintData.decimals);
    return mintData.supply.div(decimalDivisor).toNumber();
}

const getUserVotesAndPrice = async (program, priceAccount, user) => {
    if (user === null) return [0, 0];
    const userPDA = await votingUtils.findUser(program, priceAccount, user);
    const userInfo = await votingUtils.getUserInfo(program, userPDA[0]);
    console.log(userInfo)
    const userVotes = userInfo === null ? 0 : BD.divide(userInfo.votes.toString(), ONE_BN.toString(), 2);
    const userPrice = userInfo === null ? 0 : userInfo.price.toNumber() / ONE;
    return [userVotes, userPrice];
}

export const getVotingData = async (connection, vaultData, user) => {
    const program = votingUtils.priceProgram(connection, window.solana);
    const storeAuthority = await votingUtils.findStoreAuthority(program, vaultData.priceAccount);
    const [tokenInfo, priceAccountData, userVotesAndPrice] = await Promise.all([
        program.account.tokenInfo.fetch(storeAuthority[0]),
        program.account.externalPriceAccount.fetch(vaultData.priceAccount),
        getUserVotesAndPrice(program, vaultData.priceAccount, user)
    ]);
    return  {
        authority: storeAuthority,
        tokenInfo,
        totalVotes: tokenInfo.totalVotes.div(ONE_BN).toNumber(),
        reservePrice: vaultData.fractionSupply * priceAccountData.pricePerShare.toNumber() / ONE,
        userVotes: userVotesAndPrice[0],
        userPrice: userVotesAndPrice[1],
    }
}

export const getTokenBalance = async (connection, mint, owner) => {
    const ata = await programUtils.getATA(owner, mint);
    return await programUtils.getTokenAccountBalance(connection, ata);
}

export const getAuctionData = async (connection, vault, settings) => {
    const program = auctionUtils.auctionProgram(connection, window.solana);
    const [auctionAuthority, auctionPDA] = await Promise.all([
        auctionUtils.findAuthorityAccount(program),
        auctionUtils.findAuctionAccount(program, vault)
    ]);
    const auctionData = await auctionUtils.fetchAuctionData(program, auctionPDA[0]);
    settings = auctionData === null ? settings : auctionData.settings;
    const [settingsData, authorityData] = await Promise.all([
        auctionUtils.fetchSettingsData(program, settings),
        auctionUtils.fetchAuthorityData(program, auctionAuthority[0])
    ]);
    return {
        authority: auctionAuthority,
        pda: auctionPDA,
        data: auctionData,
        settings: settingsData,
        owner: authorityData?.owner
    }

}

export const getUserData = async (connection, vaultData, user) => {
    const priceProgram = votingUtils.priceProgram(connection, window.solana);
    const auctionProgram = auctionUtils.auctionProgram(connection, window.solana);

    const pricePDA = await votingUtils.findUser(priceProgram, vaultData.priceAccount, user);
    const auctionPDA = await auctionUtils.findAuctionAccount(auctionProgram, vaultData.publicKey);
    const bidAccount = await auctionUtils.findBidAccount(auctionProgram, auctionPDA[0], user);

    const [bidData, voteInfo, fractionATAInfo, balance, tokenAccountRentExemptBalance] = await Promise.all([
        auctionUtils.getBidData(auctionProgram, bidAccount[0]),
        votingUtils.getUserInfo(priceProgram, pricePDA[0]),
        programUtils.associatedTokenAccount(connection, user, vaultData.fractionMint),
        programUtils.getBalance(connection, user),
        Token.getMinBalanceRentForExemptAccount(connection)
    ]);
    const [fractionATA, hasFractionATA, fractionATABalance] = fractionATAInfo;

    return {
        publicKey: user,
        pricePDA,
        fractionATA,
        hasFractionATA,
        fractionATABalance,
        voteInfo,
        bidAccount,
        bid: bidData,
        balance,
        tokenAccountRentExemptBalance
    }
}

export const getMarketPrice = async () => {
    const res = await fetch("https://api.raydium.io/coin/price");
    const data = await res.json();
    return { SOL: data.SOL, MIMO: data.MIMO };
}
import React from 'react';

export const Footer = () => {

    return (
        <div className="social">
            <div>
                <a className="gitbook" href="https://docs.millionmonke.com/" target="_blank" rel="noreferrer"><img src="/social_icons_8bit/GitBook.png" alt="gitbook" width="60rem"></img></a>
            </div>
            <br></br>
            <div>
                <a className="twitter" href="https://twitter.com/MeltLabs" target="_blank" rel="noreferrer"><img src="/social_icons_8bit/Twitter.png" alt="twitter" width="60rem"></img></a>
            </div>
            <br></br>
            <div>
                <a className="discord"href="https://discord.com/invite/hjmxRnGw7h" target="_blank" rel="noreferrer"><img src="/social_icons_8bit/Discord.png" alt="discord" width="60rem"></img></a>
            </div>
            <br></br>
            <div>
                <a className="medium" href="https://medium.com/millionmonke" target="_blank" rel="noreferrer"><img src="/social_icons_8bit/Medium.png" alt="medium" width="60rem"></img></a>
            </div>
            <br></br>
            <div>
                <a className="mail" href="mailto:info@millionmonke.com" target="_blank" rel="noreferrer"><img src="/social_icons_8bit/Mail.png" alt="mail" width="60rem"></img></a>
            </div>
            <br></br>
            <div>
                <a className="pfp" href="https://pfp.millionmonke.com/" target="_blank" rel="noreferrer"><img src="/social_icons_8bit/pfp.png" alt="pfp" width="60rem"></img></a>
            </div>
        </div>
    )
}
import * as utils from '../utils';
import * as auctionUtils from './utils';
import { Keypair, Transaction, SYSVAR_RENT_PUBKEY, SystemProgram } from '@solana/web3.js';
import { NATIVE_MINT, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { BN } from 'bn.js';

export const placeBid = async (connection, user, info, bidAmount) => {
    return await utils.solanaAction(connection, 'place_bid', async () => {
        const bidder = user.publicKey;
        if (user.balance < bidAmount) return utils.failure("You do not have enough funds in your wallet to make the desired bid");

        const program = auctionUtils.auctionProgram(connection, window.solana);

        const [auction, ] = info.auction.pda;
        const auctionData = info.auction.data;

        const currentTimestamp = Math.floor(Date.now() / 1000);
        if (currentTimestamp >= auctionData.endTimestamp.toNumber()) return utils.failure("The auction has finished!");

        const settings = auctionData.settings;
        const paymentTreasury = auctionData.paymentTreasury;

        const [bid, bidBump] = await auctionUtils.findBidAccount(program, auction, bidder);
        const bidData = await auctionUtils.fetchBidData(program, bid);

        if (bidData !== null) return utils.failure("You must withdraw your existing bid before placing a new one!");

        const [topBid, ] = await auctionUtils.findBidAccount(program, auction, auctionData.topBidder);
        const topBidData = await auctionUtils.fetchBidData(program, topBid);
        const topBidTokenAccount = topBidData.tokenAccount;

        // Create the user's bid token account
        const bidTokenAccountKeypair = Keypair.generate();
        const bidTokenAccount = bidTokenAccountKeypair.publicKey;

        const transaction = new Transaction();
        const signers = [];

        const balanceNeeded = user.tokenAccountRentExemptBalance;
        const payingTokenAccount = utils.addCreateNativeTokenAccountInstructionSync(transaction, signers, bidder, bidder, balanceNeeded, bidAmount);

        bidAmount = utils.toBN(bidAmount);
        
        const settingsData = info.auction.settings;
        const MAX_INCREMENT = new BN(1e9);
        const minIncrease = auctionData.topBid.mul(settingsData.bidIncrement).div(MAX_INCREMENT);
        if (auctionData.topBid.add(minIncrease).gt(bidAmount)) return utils.failure("Bid must be at least 1% higher than the current top bid!");

        transaction.add(
            program.instruction.placeBid(bidBump, bidAmount, {
                accounts: {
                    auction,
                    settings,
                    vault: info.vault.publicKey,
                    paymentTreasury,
                    topBid,
                    topBidTokenAccount,
                    bid,
                    bidTokenAccount,
                    paymentMint: NATIVE_MINT,
                    payingTokenAccount,
                    bidder,
                    tokenProgram: TOKEN_PROGRAM_ID,
                    rent: SYSVAR_RENT_PUBKEY,
                    systemProgram: SystemProgram.programId,
                }
            })
        )
        signers.push(bidTokenAccountKeypair)

        utils.addCloseTokenAccountInstruction(transaction, payingTokenAccount, bidder);

        return [transaction, signers];

    });

}
import { Program, Provider } from "@project-serum/anchor";
import { PublicKey } from "@solana/web3.js";
import { BN } from "bn.js";
import { getFractionSupply } from "../utils";
import { decodeVault } from "../vault/schema";
import { priceIDL, priceProgramID } from "./idl";

export const priceProgram = (connection, wallet) => {
    const provider = new Provider(connection, wallet, "processed");
    return new Program(priceIDL, priceProgramID, provider);
}

export const findUser = async (program, priceAccount, user) => {
    return await PublicKey.findProgramAddress(
        [Buffer.from("user"), user.toBuffer(), priceAccount.toBuffer(), program.programId.toBuffer()], 
        program.programId
    );
}

export const findStoreAuthority = async (program, priceAccount) => {
    return await PublicKey.findProgramAddress(
        [Buffer.from("price"), priceAccount.toBuffer(), program.programId.toBuffer()], 
        program.programId
    );
}

export const getUserInfo = async (program, account) => {
    try {
        return await program.account.userInfo.fetch(account);
    } catch {
        return null;
    }   
}

const ONE = new BN(1e9);
export const getVotingBalance = async (connection, vault, user) => {
    const vaultData = await decodeVault(connection, vault);
    const priceAccount = new PublicKey(vaultData.pricingLookupAddress);
    const program = priceProgram(connection, window.solana);
    const [userPda, ] = await findUser(program, priceAccount, user);
    try {
        const userInfo = await program.account.userInfo.fetch(userPda);
        return userInfo.votes.div(ONE).toNumber();
    } catch {
        return 0;
    }   
}


export const getTotalVotes = async (connection, vault) => {
    const vaultData = await decodeVault(connection, vault);
    const priceAccount = new PublicKey(vaultData.pricingLookupAddress);
    const program = priceProgram(connection, window.solana);
    const [storeAuthorityPda, ] = await findStoreAuthority(program, priceAccount);
    const tokenInfo = await program.account.tokenInfo.fetch(storeAuthorityPda);
    return tokenInfo.totalVotes.div(ONE).toNumber();
}

export const getReservePrice = async (connection, vault) => {
    const vaultData = await decodeVault(connection, vault);
    const priceAccount = new PublicKey(vaultData.pricingLookupAddress);
    const program = priceProgram(connection, window.solana);
    const priceAccountData = await program.account.externalPriceAccount.fetch(priceAccount);
    const fractionSupply = await getFractionSupply(connection, vault);
    return (priceAccountData.pricePerShare / 1e9) * fractionSupply;
}
import { BinaryReader, BinaryWriter, deserializeUnchecked } from 'borsh'
import { encode, decode } from 'bs58'
import { PublicKey } from '@solana/web3.js';
import { findSafetyBox } from './utils';

const extendBorsh = () => {

    (BinaryReader.prototype).readPubkeyAsString = function () {
        const reader = this;
        const array = reader.readFixedArray(32);
        return encode(array);
    };

    (BinaryWriter.prototype).writePubkeyAsString = function (value) {
        const writer = this;
        writer.writeFixedArray(decode(value));
    };

    (BinaryReader.prototype).readPubkey = function () {
        const reader = this;
        const array = reader.readFixedArray(32);
        return new PublicKey(array);
    };
    
    (BinaryWriter.prototype).writePubkey = function (value) {
        const writer = this;
        writer.writeFixedArray(value.toBuffer());
    };

};

export const VAULT_ACCOUNT_SIZE = 205;
export const PRICE_ACCOUNT_SIZE = 42;

export class Vault {
    constructor(data) {this.data = data}
}

export class PriceAccount {
    constructor(data) {this.data = data}
}

export class SafetyDepositBox {
    constructor(data) {this.data = data}
}

export const VAULT_SCHEMA = new Map([

    // for deserializing
    [ Vault, { kind: 'struct', fields: [ ['key', 'u8'], ['tokenProgram', 'pubkeyAsString'], ['fractionMint', 'pubkeyAsString'], ['authority', 'pubkeyAsString'], ['fractionTreasury', 'pubkeyAsString'], ['redeemTreasury', 'pubkeyAsString'], ['allowFurtherShareCreation', 'u8'], ['pricingLookupAddress', 'pubkeyAsString'], ['tokenTypeCount', 'u8'], ['state', 'u8'], ['lockedPricePerShare', 'u64'], ], }, ],
    [ PriceAccount, { kind: 'struct', fields: [ ['discriminator', 'u64'], ['key', 'u8'], ['pricePerShare', 'u64'], ['priceMint', 'pubkeyAsString'], ['allowedToCombine', 'u8'], ], }, ],
    [ SafetyDepositBox, { kind: 'struct', fields: [ ['key', 'u8'], ['vault', 'pubkeyAsString'], ['tokenMint', 'pubkeyAsString'], ['store', 'pubkeyAsString'], ['order', 'u8'], ], }, ],

]);



export const decodeVault = async (connection, address) => {
    let accountInfo = await connection.getAccountInfo(address);
    return deserializeUnchecked(VAULT_SCHEMA, Vault, accountInfo.data).data;
};

export const decodePriceAccount = async (connection, address) => {
    let accountInfo = await connection.getAccountInfo(address);
    return deserializeUnchecked(VAULT_SCHEMA, PriceAccount, accountInfo.data);
};

export const decodeSafetyDepositBox = async (connection, vaultAccount, mint) => {
    let [pda, ] = await findSafetyBox(vaultAccount, mint);
    let accountInfo = await connection.getAccountInfo(pda);
    return deserializeUnchecked(VAULT_SCHEMA, SafetyDepositBox, accountInfo.data).data;
};

extendBorsh();


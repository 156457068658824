import React, { useMemo, useState} from 'react';
import {Container, Button, InputGroup, FormControl} from 'react-bootstrap';

import * as utils from './utils';
import * as auctionUtils from "../programs/auction/utils";

import '../styles/card-action.css';
import '../styles/vote-reserve-price.css';

const roundUp = (num, decimals=3) => {
    return Math.ceil(num * 10**decimals) / 10**decimals;
}

export const VoteReservePrice = (props) => {

    const reservePrice = useMemo(() => {
        if (utils.missing(props.reserve, props.facilitatorFee)) return 0;
        const facilitatorFee = props.facilitatorFee.toNumber() / auctionUtils.MAX_FACILITATOR_FEE;
        return roundUp(props.reserve / (1 - facilitatorFee))
    }, [props.facilitatorFee, props.reserve]);

    // Define the action name
    const action = "VoteReservePrice";
    
    // Use state hooks to store the input values
    const [data , setInputField] = useState({
        input_reserve_price: '',
        input_reserve_mimo: ''
    });

    // Manage the input handlers
    const inputsHandler = (e) =>{
        // From target get the id and value
        const { id, value } = e.target;
        // Update the state
        setInputField((prevState) => ({
         ...prevState,
         [id]: value,
       }));
    }
    
    return (
        <Container className="card-action card-action-box vote-reserve-price">
            <div id="vote-reserve-price" className="card-action card-action-title">VOTE RESERVE PRICE</div>
            <div className="card-action card-action-body vote-reserve-price">
                <div className="card-action card-action-body-title">CURRENT RESERVE PRICE: { reservePrice } SOL</div>
                <div className="card-action card-action-body-inputs">
                    <div className="card-action input nes-field">
                        <label className="card-action-input-label" htmlFor="input_reserve_price">INPUT DESIRED PRICE</label>
                        <InputGroup className="card-action-input-group">
                            <FormControl
                                id="input_reserve_price" 
                                className="card-action-input vote-reserve-price"
                                placeholder=""
                                aria-label="INPUT DESIRED PRICE"
                                aria-describedby="input_reserve_price-addon"
                                onChange={inputsHandler}
                                value={data.input_reserve_price} 
                                type="number"
                                onWheel={(e) => e.target.blur()} />
                            <InputGroup.Text 
                                id="input_reserve_price-addon"
                                className="card-action-input-addon">
                                SOL
                            </InputGroup.Text>
                        </InputGroup>
                    </div>
                    <div className="card-action input nes-field small-label">
                        <label htmlFor="input_reserve_mimo">WALLET BALANCE: { props.balance } MIMO</label>
                        <InputGroup className="card-action-input-group">
                            <FormControl
                                id="input_reserve_mimo" 
                                className="card-action-input vote-reserve-price"
                                placeholder=""
                                aria-label="INPUT DESIRED PRICE"
                                aria-describedby="input_reserve_mimo-addon"
                                onChange={inputsHandler}
                                value={data.input_reserve_mimo} 
                                type="number"
                                onWheel={(e) => e.target.blur()} />
                            <InputGroup.Text 
                                id="input_reserve_mimo-addon"
                                className="card-action-input-addon">
                                MIMO
                            </InputGroup.Text>
                        </InputGroup>
                    </div>
                </div>
                <div className="card-action card-action-footer">
                    <div className="error-box">
                        {props.error && props.error.message ? props.error.message : ""}
                    </div>
                    <div>
                        <Button 
                            className="card-action-button" 
                            type="button" 
                            variant="primary" 
                            onClick={(e) => props.actionHandler(e, {action: action, data:data})}
                        >
                            DEPOSIT AND VOTE
                        </Button>
                    </div>
                </div>
            </div>
        </Container>
    );
}
export const auctionProgramID = "4h3i7ER3q3pE5LSAedStJaYUcCKd3oXWBKPf3iUWsgGS";
export const auctionIDL = {
  "version": "0.0.0",
  "name": "auction",
  "instructions": [
    {
      "name": "init",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "signer",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "authorityBump",
          "type": "u8"
        }
      ]
    },
    {
      "name": "setOwner",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "owner",
          "isMut": false,
          "isSigner": true
        }
      ],
      "args": [
        {
          "name": "newOwner",
          "type": "publicKey"
        }
      ]
    },
    {
      "name": "createSettings",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "settings",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "owner",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "duration",
          "type": "u64"
        },
        {
          "name": "softClosePeriod",
          "type": "u64"
        },
        {
          "name": "bidIncrement",
          "type": "u64"
        },
        {
          "name": "facilitatorFee",
          "type": "u64"
        }
      ]
    },
    {
      "name": "start",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "auction",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "settings",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "paymentTreasury",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "bid",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "bidTokenAccount",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "payingTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "bidder",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "fractionMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "paymentMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "externalPricingAccount",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "outstandingFractionsTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultFractionTreasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultRedeemTreasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultPda",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "vaultProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "auctionBump",
          "type": "u8"
        },
        {
          "name": "bidBump",
          "type": "u8"
        },
        {
          "name": "bidAmount",
          "type": "u64"
        }
      ]
    },
    {
      "name": "placeBid",
      "accounts": [
        {
          "name": "auction",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "settings",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "paymentTreasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "topBid",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "topBidTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "bid",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "bidTokenAccount",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "paymentMint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "payingTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "bidder",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "bidBump",
          "type": "u8"
        },
        {
          "name": "bidAmount",
          "type": "u64"
        }
      ]
    },
    {
      "name": "withdrawBid",
      "accounts": [
        {
          "name": "auction",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "settings",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "bid",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "bidTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "toAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "bidder",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "end",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "auction",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "settings",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "paymentTreasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "feeTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "claim",
      "accounts": [
        {
          "name": "authority",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "auction",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "settings",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "destinationTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "bidder",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "lockedTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "safetyDepositBox",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultPda",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "vaultProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "fractionMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "amount",
          "type": "u64"
        }
      ]
    },
    {
      "name": "redeem",
      "accounts": [
        {
          "name": "auction",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "settings",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "paymentTreasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "redeemTreasury",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "sourceTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "destinationTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "fractionMint",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultPda",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "vaultProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "redeemer",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    }
  ],
  "accounts": [
    {
      "name": "Auction",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "startTimestamp",
            "type": "u64"
          },
          {
            "name": "endTimestamp",
            "type": "u64"
          },
          {
            "name": "paymentMint",
            "type": "publicKey"
          },
          {
            "name": "topBid",
            "type": "u64"
          },
          {
            "name": "topBidder",
            "type": "publicKey"
          },
          {
            "name": "reservePrice",
            "type": "u64"
          },
          {
            "name": "settings",
            "type": "publicKey"
          },
          {
            "name": "vault",
            "type": "publicKey"
          },
          {
            "name": "paymentTreasury",
            "type": "publicKey"
          },
          {
            "name": "feePaid",
            "type": "bool"
          },
          {
            "name": "lockedPaymentPerFraction",
            "type": "u64"
          },
          {
            "name": "bump",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "Bid",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "bidder",
            "type": "publicKey"
          },
          {
            "name": "auction",
            "type": "publicKey"
          },
          {
            "name": "amount",
            "type": "u64"
          },
          {
            "name": "timestamp",
            "type": "u64"
          },
          {
            "name": "withdrawable",
            "type": "bool"
          },
          {
            "name": "tokenAccount",
            "type": "publicKey"
          },
          {
            "name": "bump",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "Authority",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "owner",
            "type": "publicKey"
          },
          {
            "name": "bump",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "Settings",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "duration",
            "type": "u64"
          },
          {
            "name": "softClosePeriod",
            "type": "u64"
          },
          {
            "name": "bidIncrement",
            "type": "u64"
          },
          {
            "name": "facilitatorFee",
            "type": "u64"
          }
        ]
      }
    }
  ],
  "types": [
    {
      "name": "Key",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Uninitialized"
          },
          {
            "name": "SafetyDepositBoxV1"
          },
          {
            "name": "ExternalAccountKeyV1"
          },
          {
            "name": "VaultV1"
          }
        ]
      }
    },
    {
      "name": "VaultState",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Inactive"
          },
          {
            "name": "Active"
          },
          {
            "name": "Combined"
          },
          {
            "name": "Deactivated"
          }
        ]
      }
    },
    {
      "name": "VaultInstruction",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "InitVault",
            "fields": [
              {
                "defined": "InitVaultArgs"
              }
            ]
          },
          {
            "name": "AddTokenToInactiveVault",
            "fields": [
              {
                "defined": "AmountArgs"
              }
            ]
          },
          {
            "name": "ActivateVault",
            "fields": [
              {
                "defined": "NumberOfShareArgs"
              }
            ]
          },
          {
            "name": "CombineVault"
          },
          {
            "name": "RedeemShares"
          },
          {
            "name": "WithdrawTokenFromSafetyDepositBox",
            "fields": [
              {
                "defined": "AmountArgs"
              }
            ]
          },
          {
            "name": "MintFractionalShares",
            "fields": [
              {
                "defined": "NumberOfShareArgs"
              }
            ]
          },
          {
            "name": "WithdrawSharesFromTreasury",
            "fields": [
              {
                "defined": "NumberOfShareArgs"
              }
            ]
          },
          {
            "name": "AddSharesToTreasury",
            "fields": [
              {
                "defined": "NumberOfShareArgs"
              }
            ]
          },
          {
            "name": "UpdateExternalPriceAccount",
            "fields": [
              {
                "defined": "Price"
              }
            ]
          },
          {
            "name": "SetAuthority"
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 300,
      "name": "NumericalOverflowError",
      "msg": "A numerical error occurred"
    },
    {
      "code": 301,
      "name": "PriceAccountDoesNotMatchVaultAccount",
      "msg": "The given price account is not the vault account's pricing lookup address"
    },
    {
      "code": 302,
      "name": "VaultCannotCurrentlyBeCombined",
      "msg": "The vault is not currently allowed to combined"
    },
    {
      "code": 303,
      "name": "VaultNotInActiveState",
      "msg": "The vault is not in the active state"
    },
    {
      "code": 304,
      "name": "VaultNotOwnedByAuctionAuthority",
      "msg": "The vault is not owned by the auction authority"
    },
    {
      "code": 305,
      "name": "BidLessThanReservePrice",
      "msg": "The bid is not greater than or equal to the reserve price"
    },
    {
      "code": 306,
      "name": "BidTooLow",
      "msg": "The bid is either not larger or not sufficiently larger than the current bid"
    },
    {
      "code": 307,
      "name": "AuctionHasEnded",
      "msg": "The auction has already finished"
    },
    {
      "code": 308,
      "name": "AuctionHasNotEnded",
      "msg": "The auction has not yet finished"
    },
    {
      "code": 309,
      "name": "NotAuctionWinner",
      "msg": "Only the auction winner can claim the winnings"
    },
    {
      "code": 310,
      "name": "CannotWithdrawTopBid",
      "msg": "Top bid cannot be withdrawn"
    },
    {
      "code": 311,
      "name": "InvalidFacilitatorFee",
      "msg": "Invalid facilitator fee"
    },
    {
      "code": 312,
      "name": "InsufficientFunds",
      "msg": "Paying token account must have at least the bid amount of tokens"
    },
    {
      "code": 313,
      "name": "FeeAlreadyDelivered",
      "msg": "Fee has already been delivered"
    },
    {
      "code": 314,
      "name": "FeeHasNotBeenDelivered",
      "msg": "Fee has not yet been delivered"
    }
  ]
}
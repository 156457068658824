import * as utils from '../utils';
import * as auctionUtils from './utils';
import { Transaction } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';

export const end = async (connection, user, info) => {
    return await utils.solanaAction(connection, 'end_auction', async () => {

        if (info.auction.data === null) return utils.failure("The auction has not started let alone finished!");

        const currentTimestamp = Math.floor(Date.now() / 1000);
        if (currentTimestamp < info.auction.data.endTimestamp.toNumber()) return utils.failure("The auction has not finished!");

        const bidder = user.publicKey;
        const program = auctionUtils.auctionProgram(connection, window.solana);

        const [authority, ] = info.auction.authority;
        const [auction, ] = info.auction.pda;
        const auctionData = info.auction.data;
        const settings = auctionData.settings;
        const paymentTreasury = auctionData.paymentTreasury;

        const transaction = new Transaction();
        const signers = [];

        const balanceNeeded = user.tokenAccountRentExemptBalance;
        const feeTokenAccount = utils.addCreateNativeTokenAccountInstructionSync(transaction, signers, bidder, info.auction.owner, balanceNeeded, 0);

        transaction.add(
            program.instruction.end({
                accounts: {
                    authority,
                    auction,
                    vault: info.vault.publicKey,
                    settings,
                    paymentTreasury,
                    feeTokenAccount,
                    tokenProgram: TOKEN_PROGRAM_ID
                }
            })
        );

        return [transaction, signers];
    });

}
import ReactNotification from 'react-notifications-component';
import { Routes, Route } from 'react-router';
import 'react-notifications-component/dist/theme.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import "nes.css/css/nes.min.css";
import { NFTPage } from './pages/NFTPage';
import { SolanaProvider } from './components/SolanaProvider';


export const App = () => {

    return (
		<div>
			 <SolanaProvider>
				<ReactNotification />
				<Routes>
					<Route path="/" element={<NFTPage />}/>
					{/* <Route path="/auction/:vault" element={<NFTPage />}/>
					<Route path="/auction/:vault/settings/:settings" element={<NFTPage />}/> */}
				</Routes>
			</SolanaProvider>
		</div>
    );
};


import * as utils from '../utils';
import * as auctionUtils from './utils';
import * as vaultUtils from '../vault/utils';
import { Keypair, Transaction, SYSVAR_RENT_PUBKEY, SystemProgram, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { NATIVE_MINT, TOKEN_PROGRAM_ID } from '@solana/spl-token';

export const startAuction = async (connection, user, info, bidAmount, settings) => {
    return await utils.solanaAction(connection, 'start_auction', async () => {
        console.log("auction_authority:", info.auction.authority[0].toString())
        const bidder = user.publicKey;

        const percentageVotes = info.price.totalVotes / info.vault.fractionSupply;
        if (percentageVotes < 0.5) return utils.failure("The auction cannot be started until the percentage of votes surpasses 50%");

        console.log(info.price.totalVotes, info.vault.fractionSupply)

        if (!utils.isValidNumber(bidAmount)) return utils.failure("Invalid bid amount");
        if (user.balance < bidAmount) return utils.failure("You do not have enough funds in your wallet to make the desired bid");

        const reservePrice = (info.vault.priceAccountData.pricePerShare.toNumber() * info.vault.fractionSupply);
        const feePercentage = info.auction.settings.facilitatorFee.toNumber() / LAMPORTS_PER_SOL;
        const adjustedReservePrice = (reservePrice / (1 - feePercentage)) / 1e9;
        if (adjustedReservePrice > bidAmount) return utils.failure("Bid is below the current reserve price");

        const program = auctionUtils.auctionProgram(connection, window.solana);

        const [authority, ] = info.auction.authority;
        const [auction, auctionBump] = info.auction.pda;
        const [bid, bidBump] = await auctionUtils.findBidAccount(program, auction, bidder);

        const vaultFractionTreasury = info.vault.fractionTreasury;
        const vaultRedeemTreasury = info.vault.redeemTreasury;
        const externalPricingAccount = info.vault.priceAccount;
        const fractionMint = info.vault.fractionMint;
        const [vaultPda, ] = info.vault.pda;

        // Create the payment treasury
        const paymentTreasuryKeypair = Keypair.generate();
        const paymentTreasury = paymentTreasuryKeypair.publicKey;

        // Create the user's bid token account
        const bidTokenAccountKeypair = Keypair.generate();
        const bidTokenAccount = bidTokenAccountKeypair.publicKey;

        const transaction = new Transaction();
        const signers = [];
        
        const balanceNeeded = user.tokenAccountRentExemptBalance;
        const payingTokenAccount = utils.addCreateNativeTokenAccountInstructionSync(transaction, signers, bidder, bidder, balanceNeeded, bidAmount);
        const outstandingFractionsTokenAccount = utils.addCreateTokenAccountInstructionSync(transaction, signers, bidder, bidder, fractionMint, balanceNeeded);
        
        transaction.add(
            program.instruction.start(auctionBump, bidBump, utils.toBN(bidAmount), {
                accounts: {
                    authority,
                    auction,
                    settings,
                    vault: info.vault.publicKey,
                    externalPricingAccount,
                    vaultPda,
                    
                    vaultFractionTreasury,
                    vaultRedeemTreasury,
                    paymentTreasury,
                    bid,
                    bidTokenAccount,
                    payingTokenAccount,
                    outstandingFractionsTokenAccount,

                    paymentMint: NATIVE_MINT,
                    fractionMint,
                
                    bidder,
                    tokenProgram: TOKEN_PROGRAM_ID,
                    rent: SYSVAR_RENT_PUBKEY,
                    systemProgram: SystemProgram.programId,
                    vaultProgram: vaultUtils.VAULT_PROGRAM_ID,
                    
                }
            })
        )
        signers.push(paymentTreasuryKeypair, bidTokenAccountKeypair)

        utils.addCloseTokenAccountInstruction(transaction, payingTokenAccount, bidder);

        return [transaction, signers];

    });

}
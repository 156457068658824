import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment-duration-format';

const Countdown = (props) => {
    // NOTE: this method will return the seconds since the epoch in UTC. 
    // This because we are expecting that props.endAt value will be in UTC.
    // To get seconds since epoch in local timezone use moment().unix();
    const now = moment.utc().unix();
    // Get the time difference between now and the auction start time
    let difference = props.endAt - now;
    // Init seconds var and set it to the difference
    const [seconds, setSeconds] = useState(difference);
    // Check if countdown is over
    const isActive = difference > 0;

    // Update the seconds var every second
    useEffect(() => {
        // Init and interval timer
        let interval = null;

        // Check if is active
        if (isActive) {
            // Set the interval to 1 second
            interval = setInterval(
                // Update the seconds var
                () => {setSeconds(seconds => seconds - 1)},
                // Make this every seconds
                1000
            );
        } 
        // Clear interval
        return () => clearInterval(interval);
    }, [isActive, seconds]);
    
    // If we have almost 1 seconds to be auctioned, we will show the countdown
    const formatted = difference > 0 
        // Format duration by seconds input
        ? "TIME LEFT: " + (moment.duration(difference, 'seconds')
            // To a human readable format
            .format('dd:hh:mm:ss')) 
        // Otherise we will show the end of the auction
        : 'AUCTION HAS ENDED';
    // Return the countdown
    return (
        <div className='countdown'>{formatted ? ( formatted) : ""}</div>
    );
};

export default Countdown;
import { PublicKey } from "@solana/web3.js";

export const VAULT_PROGRAM_ID = new PublicKey("FMcRJeEKBjD1LU5UyBHvorpep4hVg48ff2C36NX83vtY");

export const findVaultPDA = async (vaultAccount) => {
	return await PublicKey.findProgramAddress(
		[Buffer.from("vault"), VAULT_PROGRAM_ID.toBuffer(), vaultAccount.toBuffer()], 
		VAULT_PROGRAM_ID
	); 
};

export const findSafetyBox = async (vaultAccount, mint) => {
	return PublicKey.findProgramAddress(
		[Buffer.from("vault"), vaultAccount.toBuffer(), mint.toBuffer()], 
		VAULT_PROGRAM_ID
	);
};

export const isVault = async (connection, vault) => {
    let accountInfo = await connection.getAccountInfo(vault);
    return accountInfo !== null
}
import * as utils from '../utils';
import * as auctionUtils from './utils';
import { Transaction } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';

export const withdrawBid = async (connection, user, info) => {
    return await utils.solanaAction(connection, 'withdraw_bid', async () => {

        const bidder = user.publicKey;
        const program = auctionUtils.auctionProgram(connection, window.solana);

        const [auction, ] = info.auction.pda;
        const auctionData = info.auction.data;

        if (auctionData === null) return utils.failure("The auction has not started");

        const settings = auctionData.settings;

        if (auctionData.topBidder.toString() === bidder.toString()) return utils.failure("The top bid cannot be withdrawn!");

        const [bid, ] = await auctionUtils.findBidAccount(program, auction, bidder);
        const bidData = await auctionUtils.getBidData(program, bid);

        if (bidData === null) return utils.failure("You don't have an existing bid!");

        const bidTokenAccount = bidData.tokenAccount;

        const transaction = new Transaction();
        const signers = [];

        const balanceNeeded = user.tokenAccountRentExemptBalance;
        const toAccount = utils.addCreateNativeTokenAccountInstructionSync(transaction, signers, bidder, bidder, balanceNeeded, 0);

        transaction.add(
            program.instruction.withdrawBid({
                accounts: {
                    auction,
                    settings,
                    vault: info.vault.publicKey,
                    bid,
                    bidTokenAccount,
                    toAccount,
                    bidder,
                    tokenProgram: TOKEN_PROGRAM_ID,
                }
            })
        )

        utils.addCloseTokenAccountInstruction(transaction, toAccount, bidder);

        return [transaction, signers];

    });

}
import React, { useMemo } from 'react';
import { If, Then, Else } from 'react-if';
import { isBrowser} from 'react-device-detect';
import { ProgressBar } from 'react-bootstrap';
import * as utils from './utils';

const toPercentage = (x) => 100 * x;

export const Description = React.memo((props) => {

    const percentage = useMemo(() => {
        if (utils.missing(props.totalVotes, props.supply)) return 0;
        if (props.supply === 0) return 0;
        return toPercentage(props.totalVotes / props.supply).toFixed(4);
    }, [props.totalVotes, props.supply]);

    const supply = useMemo(() => {
        if (utils.missing(props.supply)) return 0;
        return props.supply.toLocaleString();
    }, [props.supply]);

    const marketValuation = useMemo(() => {
        if (utils.missing(props.marketPrice, props.supply)) return 0;
        return (props.marketPrice.MIMO * props.supply).toLocaleString();
    }, [props.supply, props.marketPrice]);

    const solMarketValuation = useMemo(() => {
        if (utils.missing(props.marketPrice, props.supply)) return 0;
        return (props.marketPrice.MIMO * props.supply / props.marketPrice.SOL).toFixed(2).toLocaleString();
    }, [props.supply, props.marketPrice]);

    return (
        <div className="description">
            <If condition={isBrowser}>
                <Then>
                    <div className="underline title-label">DESCRIPTION:</div>        
                    <div className="space-div ">MIMO (Million Monke) is the first major NFT fractionalization project on Solana offering access to one of the top Solana Monkey Business NFTs! It is a market driven token backed by the underlying asset Solana Monkey Business #3408 which we’ve affectionately named MIMO.</div>
                    <div className="info-box">
                        <div>
                            <div className="underline">FRACTIONS:</div>
                            <div>{ supply }</div>
                        </div>

                        <div>
                            <div className="underline">MARKET VALUATION:</div>
                            <div>{ solMarketValuation } SOL</div>
                            <div className="grey-text">≈$ {marketValuation} </div>
                        </div>

                        <div>
                            <div className="underline">FRACTIONS DEPOSITED:</div>
                            <div> <ProgressBar className="custom-progressbar" now={ percentage } /> </div>
                            <div>{ percentage }%</div>
                        </div>
                    </div>
				</Then>
				<Else>
                    <div className="underline data-text-mobile">DESCRIPTION:</div>        
                    <div className="space-div data-text-mobile">MIMO (Million Monke) is the first major NFT fractionalization project on Solana offering access to one of the top Solana Monkey Business NFTs! It is a market driven token backed by the underlying asset Solana Monkey Business #3408 which we’ve affectionately named MIMO.</div>
                    <div className="space-div">

                        <div className="underline center-text data-text-mobile">FRACTIONS:</div> 
                        <div className="center-text data-text-mobile">{ supply }</div>

                        <div style={{marginTop: "20px"}}></div>

                        <div className="underline center-text data-text-mobile">VALUATION:</div>
                        <div className="center-data-point data-text-mobile">{`${solMarketValuation} SOL`}</div>

                        <div className="underline data-text-mobile">FRACTIONS DEPOSITED:</div>
                        <div> <ProgressBar className="custom-progressbar data-text-mobile" now={ percentage } /> </div>
                        <div className="data-text-mobile">{ percentage }%</div>

                    </div>
				</Else>
			</If>
        </div>
    )
});
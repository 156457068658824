import React, {useState} from 'react';

import {Container, Button, InputGroup, FormControl} from 'react-bootstrap';

import '../styles/card-action.css';
import '../styles/deposit-mimo.css';

export const RedeemFractions = (props) => {
    // Define the action name
    const action = "RedeemFractions";
    
    // Use state hoocks to store the input values
    const [data , setInputField] = useState({
        redeem_amount: '',
    });

    // Manage the input handlers
    const inputsHandler = (e) =>{
        // From target get the id and value
        const { id, value } = e.target;
        // Update the state
        setInputField((prevState) => ({
         ...prevState,
         [id]: value,
       }));
    }
    
    return (
        <Container className="card-action card-action-box vote-reserve-price">
            <div className="card-action card-action-title">DEPOSIT MIMO TO REDEEM SOL</div>
            <div className="card-action card-action-body deposit-mimo">
                <div className="card-action card-action-body-title">VAULT BALANCE: { props.redeemableBalance.toFixed(2) } SOL</div>
                <div className="card-action card-action-body-inputs center">
                    <div className="card-action input nes-field small-label">
                        <label className="card-action-input-label" htmlFor="input_reserve_price">WALLET BALANCE: { props.balance } MIMO</label>
                        <InputGroup className="card-action-input-group">
                            <FormControl
                                id="redeem_amount" 
                                className="card-action-input deposit-mimo"
                                placeholder=""
                                aria-label="INPUT DESIRED PRICE"
                                aria-describedby="redeem_amount-addon"
                                onChange={inputsHandler}
                                value={data.redeem_amount} 
                                type="number"
                                onWheel={(e) => e.target.blur()}/>
                            <InputGroup.Text 
                                id="redeem_amount-addon"
                                className="card-action-input-addon">
                                MIMO
                            </InputGroup.Text>
                        </InputGroup>
                    </div>
                </div>
                <div className="card-action card-action-footer">
                    <div className="error-box">
                        {props.error && props.error.message ? props.error.message : ""}
                    </div>
                    <div><Button className="card-action-button" type="button" variant="primary" onClick={(e) => props.actionHandler(e, {action: action, data:data})}>REEDEM</Button></div>
                </div>
            </div>
        </Container>
    );
}